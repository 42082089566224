import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { AppLogo } from '@hawkins/components';
import { appLogo } from 'styles';

import { TranslationNamespace } from 'types';
import { useAnalytics } from 'hooks/useAnalytics';

export function NetflixFullLogo() {
  const router = useRouter();
  const { t } = useTranslation(TranslationNamespace.LEP);
  const { logNavigationClick } = useAnalytics();
  const classes = appLogo();

  return (
    <AppLogo
      studioLogo
      classes={{ ...classes }}
      name={t('legal_requests')}
      onClick={() => {
        logNavigationClick('App Logo (Home)');
        router.push('/');
      }}
    />
  );
}
