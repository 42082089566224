import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';
import { useMediaQuery, useTheme } from '@material-ui/core';

import {
  Button,
  HorizontalNavigation,
  Menu,
  MenuItem,
  NavigationMenuGroup,
  NavigationMenuItem,
  NavigationUserControls,
  HawkinsProvider,
} from '@hawkins/components';
import { fontWeights, sizings, spacings } from '@hawkins/variables';
import StudioLogo from '@hawkins/components/lib/navigation/studioLogo';

import { TranslationNamespace } from 'types';
import { NetflixFullLogo } from '../Logos';
import { LanguageSelector } from 'components/navigation/LanguageSelector';
import { useAnalytics } from 'hooks/useAnalytics';

export function SignedOutHeader() {
  const theme = useTheme();
  const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const router = useRouter();
  const { t } = useTranslation(TranslationNamespace.LEP);
  const { logLanguageSelection, logNavigationClick } = useAnalytics();

  const handleLanguageSelection = async (lang: string) => {
    logLanguageSelection(lang);
    await setLanguage(lang);
  };

  return (
    <HawkinsProvider theme="dark">
      {largerThanMd ? (
        <HorizontalNavigation
          logo={<NetflixFullLogo />}
          userControls={
            <NavigationUserControls
              actions={
                <LanguageSelector
                  onLanguageSelected={(loc) => handleLanguageSelection(loc)}
                />
              }
            />
          }
        >
          <NavigationMenuGroup>
            <NavigationMenuItem
              active={router.asPath === '/faq'}
              onClick={() => {
                logNavigationClick('FAQ');
                router.push('/faq');
              }}
            >
              {t('link_faq')}
            </NavigationMenuItem>
          </NavigationMenuGroup>
        </HorizontalNavigation>
      ) : (
        <HorizontalNavigation
          userControls={
            <NavigationUserControls
              actions={
                <LanguageSelector
                  hideSelectedLanguage
                  onLanguageSelected={(loc) => handleLanguageSelection(loc)}
                />
              }
            />
          }
        >
          <Menu
            showCaret
            trigger={
              <Button
                icon={StudioLogo}
                iconProps={{ style: { marginRight: spacings.space1 } }}
                style={{
                  padding: spacings.space0,
                  fontSize: sizings.size1,
                  fontWeight: fontWeights.fontWeight2,
                  textAlign: 'left',
                }}
                variant="tertiary"
              >
                {t('legal_requests')}
              </Button>
            }
          >
            <MenuItem
              onClick={() => {
                logNavigationClick('FAQ');
                router.push('/faq');
              }}
            >
              {t('link_faq')}
            </MenuItem>
          </Menu>
        </HorizontalNavigation>
      )}
    </HawkinsProvider>
  );
}
