import Head from 'next/head';
import { Children } from 'types';

import { Box } from '@hawkins/components';
import { spacings } from '@hawkins/variables';

import { Header } from './navigation/header/Header';
import { makeStyles } from '@hawkins/styles';

export const usePageStyles = makeStyles({
  main: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '80vw',
    maxWidth: 1400,
    margin: 'auto',
    height: '100%',
    paddingTop: spacings.space6,
    paddingBottom: spacings.space6,
  },
});

export interface PageProps extends Children {
  fullScreen?: boolean;
}

/**
 * A wrapper that applies some general layout and styles used on every page.
 * @param fullScreen - Remove the default layout constraints on the body. Optional.
 */
export function Page({ children, fullScreen = false }: PageProps) {
  const classes = usePageStyles();
  return (
    <>
      <Head>
        <title>Netflix Law Enforcement Portal</title>
        <link href="/images/favicon.jpg" rel="icon" />
      </Head>
      <main
        className={fullScreen ? '' : classes.main}
        style={{ backgroundColor: '#f7f7f7' }}
      >
        <Header />
        {fullScreen ? (
          children
        ) : (
          <Box className={fullScreen ? '' : classes.container}>{children}</Box>
        )}
      </main>
    </>
  );
}
