import { useRouter } from 'next/router';

import { IconGlobe } from '@hawkins/icons/lib/iconGlobe';
import { Button, Menu, MenuItem } from '@hawkins/components';
import { makeStyles } from '@hawkins/styles';
import { isDeployProdEnv } from 'utils';

export const languageSelectorStyles = makeStyles({
  popper: {
    maxHeight: '75vh',
    overflowY: 'auto',
  },
});

// Given language map and languages to remove
// will remove if in prod
export const deleteIfProd = (
  langMap: { [index: string]: string },
  toRemove: string[]
) => {
  if (isDeployProdEnv()) {
    return Object.keys(langMap).filter((loc) => !toRemove.includes(loc));
  }
  return Object.keys(langMap);
};

interface LanguageSelectorProps {
  onLanguageSelected?: { (s: string): void };
  hideSelectedLanguage?: boolean;
}

export function LanguageSelector({
  onLanguageSelected = () => '',
  hideSelectedLanguage = false,
}: LanguageSelectorProps) {
  const styles = languageSelectorStyles();
  const { locale } = useRouter();
  const localeToLangMap: { [index: string]: string } = {
    da: 'Danish',
    de: 'Deutsch',
    en: 'English',
    'en-GB': 'English (United Kingdom)',
    es: 'Español',
    'es-ES': 'Español (España)',
    fr: 'Français',
    he: 'עברית',
    it: 'Italiano',
    hu: 'Magyar',
    nb: 'Norwegian Bokmål',
    nl: 'Nederlands',
    pl: 'Polskie',
    pt: 'Português (Brasil)',
    'pt-PT': 'Português (Portugal)',
    ro: 'Română',
    fi: 'Suomi',
    sv: 'svenska',
    cs: 'čeština',
    el: 'Ελληνικά',
  };

  const removeFromProd: any[] = [];
  const languages = deleteIfProd(localeToLangMap, removeFromProd);

  return (
    <Menu
      showCaret
      classes={{
        floatingUI: styles.popper,
      }}
      trigger={
        <Button icon={IconGlobe} variant="tertiary">
          {locale && !hideSelectedLanguage ? localeToLangMap[locale] : null}
        </Button>
      }
    >
      {languages.map((loc) => {
        return (
          <MenuItem
            key={loc}
            onClick={() => onLanguageSelected(loc)}
            selectType="single"
            selected={locale === loc}
          >
            {localeToLangMap[loc]}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
